/*!
 * --- MODAL
*/
.modal-backdrop.fade
  opacity: 0.9

.modal.fade:not(.show)
  opacity: 1

.modal
  color #485169

  &-header
    padding 1rem 2rem
    display flex
    align-items center
    justify-content space-between
    border-bottom 0
    @media sm
      padding 1rem

    .close
      margin 0 0 0 auto
      padding 0

      span
        display block
        width 24px
        height 24px
        bg(center,contain)
        background-image url('../icons/close.svg')

  &-title
    font-family fontRusso
    font-size 24px
    @media sm
      font-size 1rem

  &-body
    padding 1rem 2rem
    line-height 1.4
    @media sm
      padding 1rem
      font-size 14px

    & > ul
      list-style-type none

      li
        margin-bottom 1rem

      &.list-unstyled
        margin-left 1rem


.faqList
  color #485169

.faqListItem

  &:not(:first-child)
    margin-top 2rem

  &:not(:last-child)
    border-bottom 1px solid #D0D0D0
    padding-bottom 1rem

  &-title
    font-size 18px
    font-weight 500
    margin-bottom 25px


.modalContactsHeader
  border-bottom 1px solid #D0D0D0
  margin-bottom 3rem

  .row
    margin 0 -2rem

  .col
    padding 0 2rem

  &-left
    border-right 1px solid #D0D0D0

  &-left,
  &-right
    margin-bottom 45px


.contactInfo

  &-title
    font-family fontRusso
    font-size 18px
    margin-bottom 1rem

  &-item
    display inline-flex
    align-items center

    &:not(:last-child)
      margin-bottom 2rem
      @media lg
        margin-bottom 1rem

    &-phone
      display flex
      align-items center
      justify-content space-between

    .icon
      flex none
      display inline-block
      width 20px
      height 20px
      bg(center,contain)
      margin-right 1rem

      &-location
        background-image url('../icons/location.svg')
      &-email
        background-image url('../icons/email.svg')
      &-phone
        background-image url('../icons/phone.svg')
      &-worktime
        background-image url('../icons/worktime.svg')


.contactSocials
  display inline-flex
  align-items center
  margin-bottom 0
  list-style-type none

  li
    margin-bottom 0

    &:not(:last-child)
      margin-right 0.5rem

  .icon
    flex none
    display block
    width 24px
    height 24px
    border-radius 4px
    overflow hidden
    margin-right 0

    &-whatsapp
      background-image url('../icons/whatsapp.png')
    &-viber
      background-image url('../icons/viber.png')
    &-line
      background-image url('../icons/line.png')


.contactMap
  background-color #eee
  width 100%


.contactOffice
  &-link
    display: flex
    align-items: center

    .icon
      width: 16px
      height: 16px
      fill: #485169
      margin-right: 0.2em
      margin-bottom: 4px

  .img
    display: block
    max-width: 100%
    margin-bottom: 1em
