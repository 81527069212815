/*!
 * --- ADVANTAGE
*/

.advantage
  margin-top 36px

  &-body
    background-color rgba(0, 0, 0, 0.6)
    border-radius 6px
    padding 24px 0 20px
    @media md
      padding 20px 10px 0

  &-row
    margin 0
    @media md
      justify-content center

  &-item
    flex-basis (100% / 5)
    text-align center
    color #fff
    font-size 14px
    line-height (20/14)
    @media md
      flex-basis (100% / 3)
      margin-bottom 20px
    @media xs
      flex-basis 50%
      font-size 12px

  &-icon
    fill #fff
    width 100%
    height 40px
    margin-bottom 10px

  &-txt
    max-width 200px
    margin 0 auto
