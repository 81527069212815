@font-face {
    font-family: 'Russo One';
    src: local('Russo One'), local('RussoOne'),
        url('../fonts/RussoOne.woff2') format('woff2'),
        url('../fonts/RussoOne.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'),
        url('../fonts/hinted-Roboto-Regular.woff2') format('woff2'),
        url('../fonts/hinted-Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('../fonts/hinted-Roboto-Bold.woff2') format('woff2'),
        url('../fonts/hinted-Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('../fonts/hinted-Roboto-Medium.woff2') format('woff2'),
        url('../fonts/hinted-Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
