/*!
 -------- UI ----------------------------------------------
*/
.banner
  display: block
  border-radius: 4px
  overflow: hidden
  margin-bottom: 28px

  .img
    max-width: 100%

.app-title
  text-align center
  font-size 36px
  text-shadow 0px 6px 12px rgba(0, 0, 0, 0.25)
  color #fff
  font-family fontRusso
  margin-bottom 30px
  @media sm
    // margin-bottom 1em
    font-size 1.6rem
  @media xs
    font-size 1.4rem

.app-panel-title
  font-family fontRusso
  font-size 18px
  color #485169

.app-blue-link
  color #0094FF

  &:hover
    text-decoration underline

.app-yellow-btn
  background-color #FFD600
  border-radius 4px
  font-size 18px
  padding 4px 15px
  color #485169
  transit()
  @media lg
    font-size 16px

  &:hover
    background linear-gradient(0deg, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.35)), #FFD600

  &--primary
    padding 10px 15px


.app-form-check
  position relative

  .form-check-input
    position absolute
    top 0
    left 0
    opacity 0
    visibility hidden

  .form-check-sub
    display inline-block
    width 16px
    height 16px
    border 1px solid #D0D0D0
    border-radius 2px
    margin-right 10px
    bg(center, 10px 8px)

  .form-check-label
    display inline-flex
    align-items center
    cursor pointer

  .form-check-input:checked ~ .form-check-label .form-check-sub,
  .form-check-input:checked ~ .form-check-sub
    background-color #FFD600
    border-color #FFD600
    background-image url('../icons/check.svg')

.mapButton
  text-align: center
  color: #fff
  padding-top: 36px

  button
    color: #fff
    outline: none

    &:hover
      opacity: 0.6
