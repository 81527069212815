@font-face {
  font-family: 'Russo One';
  src: local('Russo One'), local('RussoOne'), url("../fonts/RussoOne.woff2") format('woff2'), url("../fonts/RussoOne.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'), url("../fonts/hinted-Roboto-Regular.woff2") format('woff2'), url("../fonts/hinted-Roboto-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'), url("../fonts/hinted-Roboto-Bold.woff2") format('woff2'), url("../fonts/hinted-Roboto-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'), url("../fonts/hinted-Roboto-Medium.woff2") format('woff2'), url("../fonts/hinted-Roboto-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
/*
 -------- BASE --------------------------------------------
*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*:before,
*:after {
  box-sizing: border-box;
}
body,
html {
  padding: 0;
  border: 0;
  width: 100%;
  overflow-x: hidden;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-weight: normal;
  background-color: #fff;
  line-height: normal;
  display: flex;
  position: relative;
}
.browsehappy {
  display: block;
  margin: 0;
  padding: 30px;
  text-align: center;
  background: #e55454;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
}
.browsehappy a,
.browsehappy a:hover {
  color: #fff;
  text-decoration: underline;
}
/*
 -------- FOOTER TO BOTTOM ------------------------------------------
*/
.wrapper {
  flex: 1 1 auto;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}
.main {
  flex: 1 1 auto;
  min-height: 1vh;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
}
@media all and (max-width: 991px) {
  .main {
    padding: 50px 0;
  }
}
@media all and (max-width: 767px) {
  .main {
    padding-top: 25px;
  }
}
.header,
.footer {
  flex-shrink: 0;
}
.app-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #095998;
  background-image: url("../images/bg-wrapper.jpg");
  background-attachment: fixed;
}
/*
 -------- UI ELEMENTS ---------------------------------------------------------
*/
a {
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: all 0.25s linear;
}
a:hover {
  color: #ffd600;
  text-decoration: none;
}
strong {
  font-weight: bold;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  display: block;
  outline: none;
  line-height: 1;
  border: 0;
  background-color: rgba(255,255,255,0);
  transition: all 0.25s linear;
}
button,
button[type="submit"],
input[type="submit"] {
  cursor: pointer;
}
button[type='submit']:disabled,
input[type='submit']:disabled {
  cursor: not-allowed;
}
input[type="search"],
input[type=number] {
  -webkit-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
textarea {
  width: 100%;
  resize: none;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
/*
 -------- HELPERS ----------
*/
.hide {
  display: none;
}
body > .modal-backdrop {
  display: none;
}
body > .modal.show ~ .modal-backdrop {
  display: block;
}
/*
 -------- iFRAME -----------
*/
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
/*
 -------- UI ----------------------------------------------
*/
.banner {
  display: block;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 28px;
}
.banner .img {
  max-width: 100%;
}
.app-title {
  text-align: center;
  font-size: 36px;
  text-shadow: 0px 6px 12px rgba(0,0,0,0.25);
  color: #fff;
  font-family: 'Russo One', sans-serif;
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .app-title {
    font-size: 1.6rem;
  }
}
@media all and (max-width: 575px) {
  .app-title {
    font-size: 1.4rem;
  }
}
.app-panel-title {
  font-family: 'Russo One', sans-serif;
  font-size: 18px;
  color: #485169;
}
.app-blue-link {
  color: #0094ff;
}
.app-blue-link:hover {
  text-decoration: underline;
}
.app-yellow-btn {
  background-color: #ffd600;
  border-radius: 4px;
  font-size: 18px;
  padding: 4px 15px;
  color: #485169;
  transition: all 0.25s linear;
}
@media all and (max-width: 1199px) {
  .app-yellow-btn {
    font-size: 16px;
  }
}
.app-yellow-btn:hover {
  background: linear-gradient(0deg, rgba(255,255,255,0.35), rgba(255,255,255,0.35)), #ffd600;
}
.app-yellow-btn--primary {
  padding: 10px 15px;
}
.app-form-check {
  position: relative;
}
.app-form-check .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.app-form-check .form-check-sub {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #d0d0d0;
  border-radius: 2px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 8px;
}
.app-form-check .form-check-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.app-form-check .form-check-input:checked ~ .form-check-label .form-check-sub,
.app-form-check .form-check-input:checked ~ .form-check-sub {
  background-color: #ffd600;
  border-color: #ffd600;
  background-image: url("../icons/check.svg");
}
.mapButton {
  text-align: center;
  color: #fff;
  padding-top: 36px;
}
.mapButton button {
  color: #fff;
  outline: none;
}
.mapButton button:hover {
  opacity: 0.6;
}
/*
 -------- HEADER ------------------------------------------
*/
.burger {
  display: block;
  width: 30px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  display: none;
  z-index: 5;
  outline: 0;
}
@media all and (max-width: 991px) {
  .burger {
    display: block;
  }
}
.burger:focus {
  outline: 0;
}
.burger span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  position: absolute;
  left: 0;
  transition: all 0.25s linear;
}
.burger span:first-child {
  top: 0;
}
.burger span:nth-child(2) {
  top: 50%;
  margin-top: -1px;
}
.burger span:last-child {
  top: auto;
  bottom: 0;
}
.burger.is-active span {
  top: 50%;
  bottom: auto;
  margin-top: -1px;
}
.burger.is-active span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}
.burger.is-active span:first-child {
  transform: rotate(45deg);
}
.burger.is-active span:last-child {
  transform: rotate(-45deg);
}
.header {
  background-color: #fff;
  color: #485169;
  padding: 13px 0;
  position: relative;
}
.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media all and (max-width: 991px) {
  .header-right {
    padding-right: 50px;
  }
}
@media all and (max-width: 767px) {
  .header-right {
    padding-right: 70px;
  }
}
.headerLogo {
  display: block;
}
.headerLogo img {
  display: block;
  width: 100%;
  max-width: 170px;
}
@media all and (max-width: 767px) {
  .headerLogo img {
    max-width: 100px;
  }
}
.headerMenu {
  display: flex;
  align-items: center;
  list-style-type: none;
  font-size: 18px;
  text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
  margin: 0 -18px;
}
@media all and (max-width: 991px) {
  .headerMenu {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    background-color: #fff;
    flex-direction: column;
    width: 50%;
    padding: 50px 15px;
    margin: 0;
    z-index: 4;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
    transition: all 0.25s linear;
  }
}
@media all and (max-width: 767px) {
  .headerMenu {
    width: 50%;
  }
}
@media all and (max-width: 575px) {
  .headerMenu {
    width: 100%;
  }
}
.headerMenu.is-active {
  right: 0;
}
.headerMenu-item {
  display: block;
  padding: 0 18px;
}
@media all and (max-width: 991px) {
  .headerMenu-item {
    padding: 15px 0;
  }
}
.headerLang {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0 0 0 35px;
}
.headerLang-item:not(:last-child) {
  margin-right: 12px;
}
.headerLang-link {
  display: block;
  padding: 2px;
  border-radius: 4px;
  background: rgba(0,0,0,0.25);
}
.headerLang-link:hover {
  background: rgba(255,214,0,0.5);
}
.headerLang-link.is-active {
  background: #0094ff;
}
.headerLang-link .icon {
  width: 36px;
  height: 26px;
}
@media all and (max-width: 767px) {
  .headerLang-link .icon {
    width: 30px;
    height: 20px;
  }
}
/*
 -------- FOOTER ------------------------------------------
*/
.footer {
  background-color: #eee;
}
/*
 * --- PJ MODULE STYLE
*/
.crLanding-body--extra {
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(0,0,0,0.25);
  border-radius: 6px;
  overflow: hidden;
}
.crLanding-body--extra .pjCrPanelLeft {
  position: relative;
}
.crLanding-body--extra .pjCrPanelLeft:before {
  content: '';
  display: block;
  position: absolute;
  top: 48px;
  bottom: 48px;
  width: 1px;
  background-color: #d0d0d0;
  right: 0;
}
@media all and (max-width: 991px) {
  .crLanding-body--extra .pjCrPanelLeft:before {
    display: none;
  }
}
.crLanding-body--checkout {
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(0,0,0,0.25);
  border-radius: 6px;
  overflow: hidden;
  padding: 45px 35px;
}
@media all and (max-width: 767px) {
  .crLanding-body--checkout {
    padding: 15px;
  }
}
.pjCrHeading {
  margin-bottom: 16px;
}
.pjCrNav {
  display: block;
  width: 100%;
  margin-bottom: 22px;
}
.pjCrNav ul {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 0 12px;
  margin: 0;
  position: relative;
}
@media all and (max-width: 991px) {
  .pjCrNav ul {
    display: block;
  }
}
.pjCrNav ul li {
  padding: 13px 0;
  flex-grow: 1;
  padding-left: 24px;
  text-align: center;
  overflow: hidden;
  position: relative;
}
@media all and (max-width: 991px) {
  .pjCrNav ul li {
    display: none;
  }
}
.pjCrNav ul li:before {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 0;
  margin-top: -12px;
  border: 2px solid #d0d0d0;
  border-radius: 50%;
}
.pjCrNav ul li:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: 50%;
  margin-top: -4px;
  left: 8px;
  background-color: rgba(255,255,255,0);
  border-radius: 50%;
}
@media all and (max-width: 991px) {
  .pjCrNav ul li.pjCrBtnActive {
    display: block;
  }
}
.pjCrNav ul li.pjCrBtnActive:before {
  border-color: #0094ff;
}
.pjCrNav ul li.pjCrBtnActive:after {
  background-color: #0094ff;
}
.pjCrNav ul li.pjCrBtnActive a {
  color: #0094ff;
}
.pjCrNav ul li.pjCrBtnActive a:before {
  background-color: #0094ff;
}
.pjCrNav ul li.pjCrBtnActive a span {
  opacity: 1;
}
.pjCrNav a {
  display: block;
  padding: 0;
  margin: 0;
  text-align: center;
  position: relative;
}
.pjCrNav a:hover {
  background-color: rgba(255,255,255,0);
}
.pjCrNav a:before,
.pjCrNav a:after {
  content: '';
  display: block;
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: #ccc;
  left: 0;
  top: 50%;
  margin-top: -1px;
  z-index: 1;
}
.pjCrNav a:after {
  left: 50%;
  z-index: 1;
}
.pjCrNav a span {
  display: inline-block;
  background-color: #fff;
  padding: 0 12px;
  position: relative;
  opacity: 0;
  z-index: 2;
}
.pjCrBtnNav {
  display: inline-flex;
  background: rgba(0,0,0,0.3);
  border-radius: 4px;
  backdrop-filter: blur(96px);
  color: #fff;
  font-size: 14px;
  box-shadow: none;
}
.pjCrBtnNav:hover {
  color: #fff;
  box-shadow: none;
}
.pjCrBtnNav:after {
  display: none;
}
.crBreadcrumbsEl {
  font-size: 14px;
  color: #d0d0d0;
  display: block;
  text-align: left;
  border-radius: 4px;
  margin: 0 12px;
}
.crBreadcrumbsEl:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}
.crBreadcrumbsEl.pjCrBtnActive {
  color: #485169;
}
.crBreadcrumbsEl.pjCrBtnPassed {
  color: #485169;
}
.landingSearchFormGroup {
  margin-bottom: 0;
  background-color: #fff;
  color: #485169;
  padding: 36px 48px;
  border-radius: 6px;
  box-shadow: 0px 6px 12px rgba(0,0,0,0.25);
}
@media all and (max-width: 991px) {
  .landingSearchFormGroup {
    padding: 24px;
    margin-bottom: 24px;
  }
}
.landingSearchFormGroup .control-label {
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 96px;
  padding-left: 120px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  font-size: 24px;
  margin-bottom: 32px;
}
@media all and (max-width: 991px) {
  .landingSearchFormGroup .control-label {
    height: 42px;
    padding-left: 54px;
    font-size: 18px;
  }
}
.landingSearchFormGroup .control-label--start {
  background-image: url("../icons/get_start.png");
}
.landingSearchFormGroup .control-label--end {
  background-image: url("../icons/get_end.png");
}
.landingSearchFormGroup .form-control {
  background-color: rgba(255,255,255,0);
  border: 0;
  box-shadow: none;
  padding: 0;
  height: auto;
}
.landingSearchFormGroup .form-control[readonly] {
  cursor: pointer;
}
.landingSearchFormGroup .input-group-addon {
  margin-right: 24px;
}
@media all and (max-width: 991px) {
  .landingSearchFormGroup .input-group-addon {
    margin-right: 10px;
  }
}
.landingSearchFormGroup .input-group-addon .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.landingSearchFormGroup .input-group-addon .icon-time {
  background-image: url("../icons/clock.svg");
}
.landingSearchFormGroup .input-group-addon .icon-calendar {
  background-image: url("../icons/calendar.svg");
}
.landingMapButton {
  margin-top: 50px;
}
.landingMapButton-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
}
@media all and (max-width: 991px) {
  .landingMapButton-body {
    font-size: 1em;
  }
}
@media all and (max-width: 575px) {
  .landingMapButton-body {
    font-size: 0.9em;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    padding: 10px;
    border-radius: 4px;
  }
}
.landingMapButton-link {
  display: block;
  color: #fff;
  text-align: center;
  position: relative;
}
.landingSearchFormButton {
  padding-top: 36px;
}
@media all and (max-width: 991px) {
  .landingSearchFormButton {
    padding-top: 24px;
  }
}
.landingSearchFormButton .btn {
  background-color: #ffd600;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  color: #485169;
  max-width: 210px;
}
.bootstrap-datetimepicker-widget {
  font-size: 14px;
  font-family: inherit;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  height: 25px;
  line-height: 20px;
  color: #d0d0d0;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: none;
  background-color: rgba(208,208,208,0.5);
}
.bootstrap-datetimepicker-widget table thead tr:first-child th.picker-switch {
  color: #485169;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th.prev,
.bootstrap-datetimepicker-widget table thead tr:first-child th.next {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 15px;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th.prev,
.bootstrap-datetimepicker-widget table thead tr:first-child th.prev:hover {
  background-image: url("../icons/arrow-left.svg");
}
.bootstrap-datetimepicker-widget table thead tr:first-child th.next,
.bootstrap-datetimepicker-widget table thead tr:first-child th.next:hover {
  background-image: url("../icons/arrow-right.svg");
}
.bootstrap-datetimepicker-widget table th.dow {
  color: #d0d0d0;
}
.bootstrap-datetimepicker-widget table td {
  border-radius: 0;
  padding-top: 2px;
}
.bootstrap-datetimepicker-widget table td.day {
  line-height: 20px;
  color: #485169;
  width: 25px;
  height: 25px;
  position: relative;
}
.bootstrap-datetimepicker-widget table td.day:hover {
  background: none;
  color: #485169;
}
.bootstrap-datetimepicker-widget table td.day:hover:before {
  background: #d0d0d0;
}
.bootstrap-datetimepicker-widget table td.day:before {
  content: '';
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  border-bottom: 0;
}
.bootstrap-datetimepicker-widget table td.new {
  color: #d0d0d0;
}
.bootstrap-datetimepicker-widget table td.today:before {
  background: rgba(208,208,208,0.4);
}
.bootstrap-datetimepicker-widget table td.active {
  background: none;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(0,0,0,0.25);
}
.bootstrap-datetimepicker-widget table td.active:before {
  background: #ffd600;
}
.bootstrap-datetimepicker-widget table td.active:hover {
  background: none;
  color: #485169;
  text-shadow: none;
}
.bootstrap-datetimepicker-widget table td.active:hover:before {
  background: #d0d0d0;
}
.bootstrap-datetimepicker-widget {
  background-color: #fff;
  font-family: inherit;
}
.bootstrap-datetimepicker-widget .timepicker-picker {
  width: 235px;
  margin: 0 auto;
}
.bootstrap-datetimepicker-widget table td {
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.bootstrap-datetimepicker-widget table td span {
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin: 0;
}
.bootstrap-datetimepicker-widget table td span.glyphicon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 10px;
}
.bootstrap-datetimepicker-widget table td span.glyphicon-chevron-up {
  background-image: url("../icons/arrow-up.svg");
}
.bootstrap-datetimepicker-widget table td span.glyphicon-chevron-down {
  background-image: url("../icons/arrow-down.svg");
}
.bootstrap-datetimepicker-widget table td span.month.active {
  background-color: #ffd600;
  color: #485169;
}
.bootstrap-datetimepicker-widget table td span.year {
  margin: 0 2px;
}
.bootstrap-datetimepicker-widget table td span.year.active {
  background-color: #ffd600;
  color: #485169;
}
.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 36px !important;
}
.bootstrap-datetimepicker-widget a[data-action] {
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 0;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute {
  width: 36px;
  height: 36px;
  line-height: 32px;
  padding-top: 4px;
  font-size: 30px;
  color: #485169;
}
.rentLeftPanel {
  color: #485169;
  background-color: #fff;
  padding: 48px 35px;
}
@media all and (max-width: 991px) {
  .rentLeftPanel {
    margin-bottom: 30px;
    padding: 15px;
    font-size: 16px;
  }
}
@media all and (max-width: 767px) {
  .rentLeftPanel {
    font-size: 14px;
  }
}
.rentLeftPanel--car {
  box-shadow: 0px 6px 12px rgba(0,0,0,0.25);
  border-radius: 6px;
}
.rentLeftPanel-title {
  margin-bottom: 10px;
}
@media all and (max-width: 991px) {
  .rentLeftPanel-title {
    margin-bottom: 15px;
  }
}
.rentLeftPanel-nav {
  justify-content: space-between;
  align-items: center;
  color: #485169;
  margin-top: 30px;
}
@media all and (max-width: 991px) {
  .rentLeftPanel-nav {
    margin-top: 15px;
  }
}
.rentLeftPanel-nav strong {
  font-weight: 500;
}
.rentLeftPanel-nav-link,
.rentLeftPanel-nav a {
  font-size: 0.8em;
  color: #0094ff;
}
.rentLeftPanel-nav-link:hover,
.rentLeftPanel-nav a:hover {
  text-decoration: underline;
}
.rentLeftPanel-info {
  color: #a2a6b2;
}
.rentInfoPanel {
  margin-top: 35px;
}
@media all and (max-width: 991px) {
  .rentInfoPanel {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }
}
@media all and (max-width: 767px) {
  .rentInfoPanel {
    display: block;
  }
}
@media all and (max-width: 991px) {
  .rentInfoPanel > .row {
    flex-grow: 1;
  }
}
.rentInfoPanel-title {
  margin-bottom: 15px;
  font-weight: 500;
}
@media all and (max-width: 991px) {
  .rentInfoPanel-title {
    margin-right: 1em;
  }
}
.rentInfoPanel-place {
  margin-bottom: 1em;
}
@media all and (max-width: 991px) {
  .rentInfoPanel-place {
    margin-right: 4em;
  }
}
@media all and (max-width: 767px) {
  .rentInfoPanel-place {
    margin-right: 0;
  }
}
.rentInfoPanel .icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.rentInfoPanel .icon-place {
  background-image: url("../icons/rent-info-place.svg");
}
.rentInfoPanel .icon-calendar {
  background-image: url("../icons/rent-info-calendar.svg");
}
.rentInfoPanel .icon-time {
  background-image: url("../icons/rent-info-time.svg");
}
.rentInfoFilterSort {
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(0,0,0,0.25);
  border-radius: 6px;
  padding: 5px 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}
@media all and (max-width: 575px) {
  .rentInfoFilterSort {
    flex-direction: column;
  }
}
.rentInfoFilterSort-item:not(:last-child) {
  margin-right: 15px;
}
@media all and (max-width: 575px) {
  .rentInfoFilterSort-item:not(:last-child) {
    margin-right: 0;
  }
}
.rentInfoFilterSort label {
  font-size: 12px;
}
.rentInfoFilterSort select {
  font-size: 13px;
}
.carList {
  padding-right: 15px;
  margin-right: -15px;
}
.carListItem {
  background-color: #fff;
  margin-bottom: 24px;
  box-shadow: 0px 6px 12px rgba(0,0,0,0.25);
  border-radius: 6px;
  padding: 36px 24px;
}
@media all and (max-width: 767px) {
  .carListItem {
    padding: 15px;
  }
}
.carListItem-img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
@media all and (max-width: 575px) {
  .carListItem-img {
    margin-bottom: 30px;
  }
}
.carListItem-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
@media all and (max-width: 767px) {
  .carListItem-header {
    margin-bottom: 15px;
  }
}
@media all and (max-width: 575px) {
  .carListItem-header {
    flex-direction: column;
  }
}
@media all and (max-width: 575px) {
  .carListItem-title {
    margin-bottom: 15px;
  }
}
.carListItem-price {
  text-align: right;
}
@media all and (max-width: 575px) {
  .carListItem-price {
    text-align: left;
  }
}
.carListItem-price-value {
  font-family: 'Russo One', sans-serif;
  font-size: 18px;
  color: #0094ff;
}
.carListItem-price-info {
  font-size: 12px;
  color: #a2a6b2;
}
.carListItem-details {
  margin-bottom: 28px;
}
@media all and (max-width: 767px) {
  .carListItem-details {
    margin-bottom: 0;
  }
}
.carListItem-details-item {
  display: block;
  border: 0;
  padding: 0;
  margin-bottom: 10px !important;
}
.carListItem-details-item:not(:last-child) {
  margin-right: 10px;
}
.carListItem-details-item .btn {
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  color: #a2a6b2;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 0 4px 6px;
  line-height: 1;
}
.carListItem-details-item .value {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
}
.carListItem-details-item .icon {
  display: block;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  width: 28px;
  height: 20px;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid rgba(255,255,255,0.25);
}
.carListItem-details-item .icon-passengers {
  background-image: url("../icons/product-passengers.svg");
}
.carListItem-details-item .icon-luggages {
  background-image: url("../icons/product-luggages.svg");
}
.carListItem-details-item .icon-doors {
  background-image: url("../icons/product-doors.svg");
}
.carListItem-details-item .icon-transmission {
  background-image: url("../icons/product-transmission.svg");
}
.carListItem-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media all and (max-width: 767px) {
  .carListItem-button {
    margin-top: 1rem;
  }
}
.carListItem-button .btn {
  background-color: #ffd600;
  border-radius: 4px;
  font-size: 18px;
  color: #485169;
}
@media all and (max-width: 1199px) {
  .carListItem-button .btn {
    font-size: 16px;
  }
}
@media all and (max-width: 767px) {
  .carListItem-button .btn {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
}
.carListItem-button .btn:not(:last-child) {
  margin-right: 0.5rem;
}
.carListItem-button .btn-whatsapp {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #1bd741;
  color: #fff;
}
@media all and (max-width: 575px) {
  .carListItem-button .btn-whatsapp {
    padding: 0.275rem 0.75rem;
  }
}
.carListItem-button .btn-whatsapp:hover {
  opacity: 0.8;
}
.carListItem-button .btn-whatsapp .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/whatsapp.png");
  margin-left: 12px;
}
@media all and (max-width: 767px) {
  .carListItem-button .btn-whatsapp .icon {
    margin-left: 0.5rem;
  }
}
.carCurrentPanel {
  margin-top: 30px;
}
.carCurrentPanel-name {
  color: #a2a6b2;
}
.carCurrentPanel-termslink {
  padding: 0;
  color: #d0d0d0;
  font-size: 14px;
  text-decoration: underline;
}
.carCurrentPanel-termslink:hover {
  color: #ffd600;
}
.rentTotalTable {
  padding: 48px;
}
@media all and (max-width: 991px) {
  .rentTotalTable {
    padding: 15px;
  }
}
.rentTotalTable-button {
  text-align: right;
}
.rentExtra {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 30px;
}
.rentExtra-title {
  margin-bottom: 15px;
}
.rentTable {
  margin-bottom: 30px;
}
.rentTableItem {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 10px;
  color: #a2a6b2;
}
@media all and (max-width: 575px) {
  .rentTableItem {
    font-size: 14px;
  }
}
.rentTableItem-name {
  font-size: 14px;
}
.rentTableItem-name strong {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}
@media all and (max-width: 575px) {
  .rentTableItem-name strong {
    font-size: 14px;
  }
}
.rentTableItem-name span {
  display: inline-block;
  margin-top: 5px;
}
.rentTableItem:nth-child(2n) {
  background-color: rgba(255,255,255,0);
}
.rentExtraItem {
  display: flex;
  align-items: center;
  color: #a2a6b2;
}
@media all and (max-width: 575px) {
  .rentExtraItem {
    flex-wrap: wrap;
  }
}
.rentExtraItem:not(:last-child) {
  margin-bottom: 15px;
}
.rentExtraItem-name {
  flex-grow: 1;
}
@media all and (max-width: 575px) {
  .rentExtraItem-name {
    margin-bottom: 10px;
    width: 100%;
    font-size: 14px;
  }
}
.rentExtraItem-value {
  padding: 0 30px;
  text-align: right;
}
@media all and (max-width: 575px) {
  .rentExtraItem-value {
    margin-left: auto;
    padding: 0 10px;
  }
}
.rentExtraItem-value strong {
  font-weight: 500;
}
@media all and (max-width: 575px) {
  .rentExtraItem-value strong {
    font-size: 14px;
  }
}
.rentExtraItem-value span {
  display: inline-block;
  margin-top: 5px;
  font-size: 12px;
}
.rentExtraItem-button {
  width: 100px;
  text-align: center;
}
.rentExtraItem-button .btn {
  font-size: 12px;
}
@media all and (max-width: 991px) {
  .pjCrFormCheckout {
    font-size: 14px;
  }
}
.pjCrFormCheckout-title {
  margin-bottom: 30px;
}
.pjCrFormCheckout .control-label {
  font-weight: 500;
  color: #a2a6b2;
  margin-bottom: 0.5rem;
}
.pjCrFormCheckout .form-check {
  padding-left: 0;
}
.pjCrFormCheckout .form-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.pjCrFormCheckout .form-group .help-block {
  position: absolute;
  top: 100%;
  left: 0;
  color: #c10000;
  font-size: 12px;
  padding-top: 4px;
  padding-left: 4px;
}
.pjCrFormCheckout .form-group.has-error .form-control {
  border-color: #c10000;
}
.pjCrFormCheckout .form-control {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  color: #485169;
}
.pjCrFormCheckout .form-control:focus {
  box-shadow: none;
}
.pjCrFormCheckout-data .pjCrFormCheckout-row .col {
  padding-bottom: 30px;
}
@media all and (max-width: 991px) {
  .pjCrFormCheckout-data .pjCrFormCheckout-row .col {
    padding-bottom: 10px;
  }
}
.pjCrFormCheckout-data .form-group {
  height: 100%;
}
@media all and (max-width: 991px) {
  .pjCrFormCheckout-data .form-group {
    height: auto;
  }
}
.pjCrFormCheckout-socials {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.pjCrFormCheckout-socials .form-check {
  margin-bottom: 10px;
}
.pjCrFormCheckout-socials .form-check:not(:last-child) {
  margin-right: 12px;
}
.pjCrFormCheckout-socials .form-check-input:checked ~ .form-check-label {
  border-color: #485169;
}
.pjCrFormCheckout-socials .form-check-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 6px 12px;
  color: #485169;
}
.pjCrFormCheckout-socials .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 6px;
}
.pjCrFormCheckout-socials .icon-whatsapp {
  background-image: url("../icons/whatsapp.png");
}
.pjCrFormCheckout-socials .icon-viber {
  background-image: url("../icons/viber.png");
}
.pjCrFormCheckout-socials .icon-line {
  background-image: url("../icons/line.png");
}
.pjCrFormCheckout-socials .icon-telegram {
  background-image: url("../icons/telegram.png");
}
@media all and (max-width: 991px) {
  .pjCrFormCheckout-captcha {
    margin-bottom: 40px;
  }
}
.pjCrFormCheckout-captcha .form-group {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}
@media all and (max-width: 991px) {
  .pjCrFormCheckout-captcha .form-group {
    display: flex;
  }
}
.pjCrFormCheckout-captcha .form-group .row {
  margin: 0;
  overflow: hidden;
}
.pjCrFormCheckout-captcha .form-group .col {
  padding: 0;
  border-style: solid;
  border-color: #485169;
  border-width: 1px 1px 1px 0;
  border-radius: 0 4px 4px 0;
}
.pjCrFormCheckout-captcha .form-group .col:first-child {
  border: 0;
}
.pjCrFormCheckout-captcha .form-group .col:last-child {
  overflow: hidden;
}
.pjCrFormCheckout-captcha .form-control {
  width: 150px;
  border-radius: 4px 0 0 4px;
}
.pjCrFormCheckout-captcha .captcha-image {
  cursor: pointer;
}
.pjCrFormCheckout-terms {
  color: #485169;
}
.pjCrFormCheckout-terms .form-check {
  display: flex;
  align-items: center;
}
.pjCrFormCheckout-buttons {
  text-align: right;
  margin-top: 15px;
}
.bookingMap {
  max-width: 600px;
  margin: 0 auto;
}
.bookingFinal {
  text-align: center;
}
.bookingCheck {
  display: block;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/check.svg");
  margin: 0 auto;
}
.bookingMsg {
  margin-bottom: 3em;
}
/*
 * --- ADVANTAGE
*/
.advantage {
  margin-top: 36px;
}
.advantage-body {
  background-color: rgba(0,0,0,0.6);
  border-radius: 6px;
  padding: 24px 0 20px;
}
@media all and (max-width: 991px) {
  .advantage-body {
    padding: 20px 10px 0;
  }
}
.advantage-row {
  margin: 0;
}
@media all and (max-width: 991px) {
  .advantage-row {
    justify-content: center;
  }
}
.advantage-item {
  flex-basis: 20%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 1.428571428571429;
}
@media all and (max-width: 991px) {
  .advantage-item {
    flex-basis: 33.333333333333336%;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 575px) {
  .advantage-item {
    flex-basis: 50%;
    font-size: 12px;
  }
}
.advantage-icon {
  fill: #fff;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}
.advantage-txt {
  max-width: 200px;
  margin: 0 auto;
}
/*
 * --- MODAL
*/
.modal-backdrop.fade {
  opacity: 0.9;
}
.modal.fade:not(.show) {
  opacity: 1;
}
.modal {
  color: #485169;
}
.modal-header {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0;
}
@media all and (max-width: 767px) {
  .modal-header {
    padding: 1rem;
  }
}
.modal-header .close {
  margin: 0 0 0 auto;
  padding: 0;
}
.modal-header .close span {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/close.svg");
}
.modal-title {
  font-family: 'Russo One', sans-serif;
  font-size: 24px;
}
@media all and (max-width: 767px) {
  .modal-title {
    font-size: 1rem;
  }
}
.modal-body {
  padding: 1rem 2rem;
  line-height: 1.4;
}
@media all and (max-width: 767px) {
  .modal-body {
    padding: 1rem;
    font-size: 14px;
  }
}
.modal-body > ul {
  list-style-type: none;
}
.modal-body > ul li {
  margin-bottom: 1rem;
}
.modal-body > ul.list-unstyled {
  margin-left: 1rem;
}
.faqList {
  color: #485169;
}
.faqListItem:not(:first-child) {
  margin-top: 2rem;
}
.faqListItem:not(:last-child) {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 1rem;
}
.faqListItem-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
}
.modalContactsHeader {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 3rem;
}
.modalContactsHeader .row {
  margin: 0 -2rem;
}
.modalContactsHeader .col {
  padding: 0 2rem;
}
.modalContactsHeader-left {
  border-right: 1px solid #d0d0d0;
}
.modalContactsHeader-left,
.modalContactsHeader-right {
  margin-bottom: 45px;
}
.contactInfo-title {
  font-family: 'Russo One', sans-serif;
  font-size: 18px;
  margin-bottom: 1rem;
}
.contactInfo-item {
  display: inline-flex;
  align-items: center;
}
.contactInfo-item:not(:last-child) {
  margin-bottom: 2rem;
}
@media all and (max-width: 1199px) {
  .contactInfo-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.contactInfo-item-phone {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contactInfo-item .icon {
  flex: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 1rem;
}
.contactInfo-item .icon-location {
  background-image: url("../icons/location.svg");
}
.contactInfo-item .icon-email {
  background-image: url("../icons/email.svg");
}
.contactInfo-item .icon-phone {
  background-image: url("../icons/phone.svg");
}
.contactInfo-item .icon-worktime {
  background-image: url("../icons/worktime.svg");
}
.contactSocials {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  list-style-type: none;
}
.contactSocials li {
  margin-bottom: 0;
}
.contactSocials li:not(:last-child) {
  margin-right: 0.5rem;
}
.contactSocials .icon {
  flex: none;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 0;
}
.contactSocials .icon-whatsapp {
  background-image: url("../icons/whatsapp.png");
}
.contactSocials .icon-viber {
  background-image: url("../icons/viber.png");
}
.contactSocials .icon-line {
  background-image: url("../icons/line.png");
}
.contactMap {
  background-color: #eee;
  width: 100%;
}
.contactOffice-link {
  display: flex;
  align-items: center;
}
.contactOffice-link .icon {
  width: 16px;
  height: 16px;
  fill: #485169;
  margin-right: 0.2em;
  margin-bottom: 4px;
}
.contactOffice .img {
  display: block;
  max-width: 100%;
  margin-bottom: 1em;
}
