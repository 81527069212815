/*!
 * --- PJ MODULE STYLE
*/
.crLanding
  &-body
    &--extra
      background-color #fff
      box-shadow 0px 6px 12px rgba(0, 0, 0, 0.25)
      border-radius 6px
      overflow hidden

      .pjCrPanelLeft
        position relative

        &:before
          pseudo()
          top 48px
          bottom 48px
          width 1px
          background-color #D0D0D0
          right 0
          @media md
            display none

    &--checkout
      background-color #fff
      box-shadow 0px 6px 12px rgba(0, 0, 0, 0.25)
      border-radius 6px
      overflow hidden
      padding 45px 35px
      @media sm
        padding 15px

.pjCrHeading
  margin-bottom 16px


.pjCrNav
  display: block
  width: 100%
  margin-bottom: 22px

  ul
    width: 100%
    list-style: none
    display: flex
    align-items: center
    background-color: #fff
    border-radius: 4px
    padding: 0 12px
    margin: 0
    position: relative
    @media md
      display: block

    li
      padding: 13px 0
      flex-grow: 1
      padding-left: 24px
      text-align: center
      overflow: hidden
      position: relative
      @media md
        display: none

      &:before
        pseudo()
        width: 24px
        height: 24px
        top: 50%
        left: 0
        margin-top: -12px
        border: 2px solid #D0D0D0
        border-radius: 50%

      &:after
        pseudo()
        width: 8px
        height: 8px
        top: 50%
        margin-top: -4px
        left: 8px
        background-color: rgba(#fff,0)
        border-radius: 50%

      &.pjCrBtnActive
        @media md
          display: block

        &:before
          border-color: #0094FF
        &:after
          background-color: #0094FF

        a
          color: #0094FF

          &:before
            background-color: #0094FF

          span
            opacity: 1

  a
    display: block
    padding: 0
    margin: 0
    text-align: center
    position: relative

    &:hover
      background-color: rgba(#fff,0)

    &:before, &:after
      pseudo()
      width: 50%
      height: 2px
      background-color: #ccc
      left: 0
      top: 50%
      margin-top: -1px
      z-index: 1

    &:after
      left: 50%
      z-index: 1

    span
      display: inline-block
      background-color: #fff
      padding: 0 12px
      position: relative
      opacity: 0
      z-index: 2

.pjCrBtnNav
  display inline-flex
  background rgba(0, 0, 0, 0.3)
  border-radius 4px
  backdrop-filter blur(96px)
  color #fff
  font-size 14px
  box-shadow none

  &:hover
    color #fff
    box-shadow none

  &:after
    display none


.crBreadcrumbsEl
  font-size 14px
  color #D0D0D0
  display block
  text-align left
  border-radius 4px
  margin 0 12px

  &:hover
    text-decoration none
    background-color #F5F5F5

  &.pjCrBtnActive
    color #485169

  &.pjCrBtnPassed
    color #485169


.landingSearchFormGroup
  margin-bottom 0
  background-color: #fff
  color: #485169
  padding: 36px 48px
  border-radius: 6px
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25)
  @media md
    padding: 24px
    margin-bottom: 24px

  .control-label
    font-size 14px
    display flex
    align-items: center
    height: 96px
    padding-left: 96px + 24
    bg(left center,contain)
    font-size: 24px
    margin-bottom: 32px
    @media md
      height: 42px
      padding-left: 42px + 12
      font-size: 18px

    &--start
      background-image: url('../icons/get_start.png')
    &--end
      background-image: url('../icons/get_end.png')

  .form-control
    background-color rgba(255,255,255,0)
    border 0
    box-shadow none
    padding 0
    height auto

    &[readonly]
      cursor pointer

  .input-group-addon
    margin-right 24px
    @media md
      margin-right 10px

    .icon
      display inline-block
      width 24px
      height 24px
      bg(center,contain)

      &-time
        background-image url('../icons/clock.svg')
      &-calendar
        background-image url('../icons/calendar.svg')


.landingMapButton
  margin-top 50px

  &-body
    display flex
    align-items center
    justify-content: center
    text-align: center
    font-size 18px
    text-shadow 0px 1px 2px rgba(0, 0, 0, 0.25)
    @media md
      font-size 1em
    @media xs
      font-size 0.9em
      background-color rgba(#000, 0.4)
      display flex
      padding 10px
      border-radius 4px

  &-link
    display block
    color #fff
    text-align center
    position relative


.landingSearchFormButton
  padding-top: 36px
  @media md
    padding-top: 24px

  .btn
    background-color: #FFD600
    border-radius: 4px
    height: 60px
    width: 100%
    color: #485169
    max-width: 210px


.bootstrap-datetimepicker-widget
  font-size 14px
  font-family inherit

  table
    thead tr:first-child th
      height 25px
      line-height 20px
      color #D0D0D0

      &:hover
        background none
        background-color rgba(#D0D0D0, 0.5)

      &.picker-switch
        color #485169

      &.prev
      &.next
        bg(center,10px 15px)


      &.prev, &.prev:hover
        background-image url('../icons/arrow-left.svg')
      &.next, &.next:hover
        background-image url('../icons/arrow-right.svg')

    th.dow
      color #D0D0D0

    td
      border-radius 0
      padding-top 2px

      &.day
        line-height 20px
        color #485169
        width 25px
        height 25px
        position relative

        &:hover
          background none
          color #485169

          &:before
            background #D0D0D0

        &:before
          pseudo()
          width 25px
          height 25px
          border-radius 50%
          background none
          top 50%
          left 50%
          transform translate(-50%,-50%)
          z-index -1
          border-bottom 0

      &.new
        color #D0D0D0

      &.today
        &:before
          background rgba(#D0D0D0, 0.4)

      &.active
        background none
        color #fff
        text-shadow 0px 1px 2px rgba(0, 0, 0, 0.25)

        &:before
          background #FFD600

        &:hover
          background none
          color #485169
          text-shadow none

          &:before
            background #D0D0D0


.bootstrap-datetimepicker-widget
  background-color #fff
  font-family inherit

  .timepicker-picker
    width 235px
    margin 0 auto

  table
    td
      width 36px
      height 36px
      line-height 36px

      span
        width 36px
        height 36px
        line-height 36px
        margin 0

        &.glyphicon
          bg(center, 15px 10px)

          &-chevron-up
            background-image url('../icons/arrow-up.svg')
          &-chevron-down
            background-image url('../icons/arrow-down.svg')

        &.month
          &.active
            background-color #FFD600
            color #485169

        &.year
          margin 0 2px

          &.active
            background-color #FFD600
            color #485169

  .datepicker-decades
    .decade
      line-height 36px !important


  a[data-action]
    width 36px
    height 36px
    line-height 36px
    padding 0

  .timepicker-hour,
  .timepicker-minute
    width 36px
    height 36px
    line-height 32px
    padding-top 4px
    font-size 30px
    color #485169


// ВЫБОР МАШИНЫ
.rentLeftPanel
  color #485169
  background-color #fff
  padding 48px 35px
  @media md
    margin-bottom 30px
    padding 15px
    font-size 16px
  @media sm
    font-size 14px

  &--car
    box-shadow 0px 6px 12px rgba(0, 0, 0, 0.25)
    border-radius 6px

  &-title
    margin-bottom 10px
    @media md
      margin-bottom 15px

  &-nav
    justify-content: space-between
    align-items: center
    color #485169
    margin-top 30px
    @media md
      margin-top 15px

    strong
      font-weight 500

    &-link, a
      font-size: 0.8em
      color #0094FF
      &:hover
        text-decoration underline

  &-info
    color #A2A6B2


.rentInfoPanel
  margin-top 35px
  @media md
    display flex
    flex-wrap wrap
    margin-top 15px
  @media sm
    display block

  & > .row
    @media md
      flex-grow 1


  &-title
    margin-bottom 15px
    font-weight 500
    @media md
      margin-right 1em

  &-place
    margin-bottom 1em
    @media md
      margin-right 4em
    @media sm
      margin-right 0

  .icon
    display inline-block
    bg(center,contain)
    width 20px
    height 20px
    margin-right 10px

    &-place
      background-image url('../icons/rent-info-place.svg')

    &-calendar
      background-image url('../icons/rent-info-calendar.svg')

    &-time
      background-image url('../icons/rent-info-time.svg')


.rentInfoFilterSort
  background-color #fff
  box-shadow 0px 6px 12px rgba(0, 0, 0, 0.25)
  border-radius 6px
  padding 5px 15px
  margin-bottom 15px
  display flex
  justify-content flex-end
  @media xs
    flex-direction column

  &-item:not(:last-child)
    margin-right 15px
    @media xs
      margin-right 0

  label
    font-size 12px

  select
    font-size 13px


.carList
  // max-height 75vh
  // overflow-y auto
  padding-right 15px
  margin-right -15px
  // @media sm
  //   max-height 500px

.carListItem
  background-color #fff
  margin-bottom 24px
  box-shadow 0px 6px 12px rgba(0, 0, 0, 0.25)
  border-radius 6px
  padding 36px 24px
  @media sm
    padding 15px

  &-img
    display block
    max-width 100%
    margin 0 auto
    @media xs
      margin-bottom 30px

  &-header
    display flex
    justify-content space-between
    margin-bottom 24px
    @media sm
      margin-bottom 15px
    @media xs
      flex-direction column

  &-title
    @media xs
      margin-bottom 15px

  &-price
    text-align right
    @media xs
      text-align left

    &-value
      font-family fontRusso
      font-size 18px
      color #0094FF

    &-info
      font-size 12px
      color #A2A6B2

  &-details
    margin-bottom 28px
    @media sm
      margin-bottom 0

    &-item
      display block
      border 0
      padding 0
      margin-bottom 10px !important
      &:not(:last-child)
        margin-right 10px

      .btn
        display inline-flex
        align-items center
        background-color: #fff
        color: #A2A6B2
        border: 1px solid #D0D0D0
        border-radius: 4px
        font-size 14px
        padding: 4px 0 4px 6px
        line-height: 1

      .value
        display: flex
        align-items: center
        justify-content: center
        width: 20px
        height: 100%

      .icon
        display block
        bg(left center,contain)
        width 28px
        height 20px
        padding-right 8px
        margin-right 8px
        border-right 1px solid rgba(255, 255, 255, 0.25)

        &-passengers
          background-image url('../icons/product-passengers.svg')
        &-luggages
          background-image url('../icons/product-luggages.svg')
        &-doors
          background-image url('../icons/product-doors.svg')
        &-transmission
          background-image url('../icons/product-transmission.svg')

  &-button
    display flex
    align-items center
    justify-content space-between
    width 100%
    @media sm
      margin-top 1rem

    .btn
      background-color #FFD600
      border-radius 4px
      font-size 18px
      color #485169
      @media lg
        font-size 16px
      @media sm
        font-size 14px
        margin-bottom 0.5rem

      &:not(:last-child)
        margin-right 0.5rem

      &-whatsapp
        flex-grow: 1
        display inline-flex
        align-items center
        justify-content: center
        text-align: center
        background-color #1BD741
        color #fff
        @media xs
          padding .275rem .75rem

        &:hover
          opacity 0.8

        .icon
          display inline-block
          width 24px
          height 24px
          bg(center,contain)
          background-image url('../icons/whatsapp.png')
          margin-left 12px
          @media sm
            margin-left 0.5rem



// Выбранная машина
.carCurrentPanel
  margin-top 30px

  &-name
    color #A2A6B2

  &-termslink
    padding 0
    color #D0D0D0
    font-size 14px
    text-decoration underline
    &:hover
      color #FFD600


// Инфо о заказе
.rentTotalTable
  padding 48px
  @media md
    padding 15px

  &-button
    text-align right


// Дополнения
.rentExtra
  border 1px solid #D0D0D0
  border-radius 4px
  padding 15px
  margin-bottom 30px

  &-title
    margin-bottom 15px


// Таблица итого
.rentTable
  margin-bottom 30px


.rentTableItem
  display flex
  justify-content space-between
  background-color #F5F5F5
  padding 10px
  color #A2A6B2
  @media xs
    font-size 14px

  &-name
    font-size 14px

    strong
      display inline-block
      font-size 16px
      font-weight 500
      @media xs
        font-size 14px
    span
      display inline-block
      margin-top 5px

  &:nth-child(2n)
    background-color rgba(#fff,0)


.rentExtraItem
  display flex
  align-items center
  color #A2A6B2
  @media xs
    flex-wrap wrap

  &:not(:last-child)
    margin-bottom 15px

  &-name
    flex-grow 1
    @media xs
      margin-bottom 10px
      width 100%
      font-size 14px

  &-value
    padding 0 30px
    text-align right
    @media xs
      margin-left auto
      padding 0 10px

    strong
      font-weight 500
      @media xs
        font-size 14px

    span
      display inline-block
      margin-top 5px
      font-size 12px

  &-button
    width 100px
    text-align center

    .btn
      font-size 12px


// Данные клиента
.pjCrFormCheckout
  @media md
    font-size 14px

  &-title
    margin-bottom 30px

  .control-label
    font-weight 500
    color #A2A6B2
    margin-bottom 0.5rem

  .form-check
    padding-left 0

  .form-group
    display flex
    flex-direction column
    justify-content space-between
    position relative

    .help-block
      position absolute
      top 100%
      left 0
      color #C10000
      font-size 12px
      padding-top 4px
      padding-left 4px

    &.has-error .form-control
      border-color #C10000

  .form-control
    border 1px solid #D0D0D0
    border-radius 4px
    color #485169

    &:focus
      box-shadow none


.pjCrFormCheckout-data

  .pjCrFormCheckout-row
    .col
      padding-bottom 30px
      @media md
        padding-bottom 10px

  .form-group
    height 100%
    @media md
      height auto


.pjCrFormCheckout-socials
  display flex
  align-items center
  flex-wrap wrap
  margin-bottom 20px

  .form-check
    margin-bottom 10px

    &:not(:last-child)
      margin-right 12px

    &-input:checked ~ .form-check-label
      border-color #485169

  .form-check-label
    display inline-flex
    align-items center
    cursor pointer
    border 1px solid #D0D0D0
    border-radius 4px
    padding 6px 12px
    color #485169

  .icon
    display inline-block
    width 24px
    height 24px
    bg(center,contain)
    margin-right 6px

    &-whatsapp
      background-image url('../icons/whatsapp.png')
    &-viber
      background-image url('../icons/viber.png')
    &-line
      background-image url('../icons/line.png')
    &-telegram
      background-image url('../icons/telegram.png')


.pjCrFormCheckout-captcha
  @media md
    margin-bottom 40px

  .form-group
    display inline-flex
    align-items center
    flex-direction row
    @media md
      display flex

    .row
      margin 0
      overflow hidden

    .col
      padding 0
      border-style solid
      border-color #485169
      border-width 1px 1px 1px 0
      border-radius 0 4px 4px 0

      &:first-child
        border 0

      &:last-child
        overflow hidden

  .form-control
    width 150px
    border-radius 4px 0 0 4px

  .captcha-image
    cursor pointer


.pjCrFormCheckout-terms
  color #485169

  .form-check
    display flex
    align-items center


.pjCrFormCheckout-buttons
  text-align right
  margin-top 15px


.bookingMap
  max-width: 600px
  margin: 0 auto


.bookingFinal
  text-align center


.bookingCheck
  display block
  width 100px
  height 100px
  bg(center,contain)
  background-image url('../icons/check.svg')
  margin 0 auto

.bookingMsg
  margin-bottom 3em
