/*!
 -------- HEADER ------------------------------------------
*/

.burger
  display block
  width 30px
  height 20px
  position absolute
  top 50%
  right 15px
  transform: translateY(-50%)
  display none
  z-index 5
  outline 0
  @media md
    display block

  &:focus
    outline 0

  span
    display block
    width 100%
    height 2px
    background-color #000
    position absolute
    left 0
    transit()

    &:first-child
      top 0

    &:nth-child(2)
      top 50%
      margin-top -1px

    &:last-child
      top auto
      bottom 0

  &.is-active

    span
      top 50%
      bottom auto
      margin-top -1px

      &:nth-child(2)
        opacity 0
        visibility hidden

      &:first-child
        transform rotate(45deg)

      &:last-child
        transform rotate(-45deg)

.header
  background-color: #fff
  color #485169
  padding: 13px 0
  position relative

  &-right
    display flex
    align-items center
    justify-content flex-end
    @media md
      padding-right: 50px
    @media sm
      padding-right: 70px


.headerLogo
  display block

  img
    display block
    width 100%
    max-width 170px
    @media sm
      max-width: 100px


.headerMenu
  display flex
  align-items center
  list-style-type none
  font-size 18px
  text-shadow 0px 1px 2px rgba(0, 0, 0, 0.25)
  margin 0 -18px
  @media md
    position fixed
    top 0
    right -100%
    height 100%
    background-color #fff
    flex-direction column
    width 50%
    padding 50px 15px
    margin 0
    z-index 4
    box-shadow 0 0 15px 0 rgba(0,0,0,0.5)
    transit()
  @media sm
    width 50%
  @media xs
    width 100%

  &.is-active
    right 0

  &-item
    display block
    padding 0 18px
    @media md
      padding 15px 0


.headerLang
  display flex
  align-items center
  list-style-type none
  margin 0 0 0 35px

  &-item
    &:not(:last-child)
      margin-right 12px

  &-link
    display block
    padding 2px
    border-radius 4px
    background rgba(0, 0, 0, 0.25)

    &:hover
      background rgba(#FFD600, 0.5)

    &.is-active
      background #0094FF

    .icon
      width 36px
      height 26px
      @media sm
        width 30px
        height 20px
